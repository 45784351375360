<template>
    <div>
        <!-- Modals -->

        <b-modal
            id="delete_modal"
            title="Confirm"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                    You are about to delete the idea:<br />
                    <strong>"{{ this.form.name }}"</strong>
                </p>
                <base-button
                    type="danger"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="delete_idea_confirm"
                    :disabled="form.loading.delete || form.loading.form"
                    :loading="form.loading.delete || form.loading.form"
                    :success="form.success.delete"
                    >Delete
                </base-button>
                <base-button
                    type="primary"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="$bvModal.hide('delete_modal')"
                    :disabled="form.loading.delete || form.loading.form"
                    >Cancel
                </base-button>
            </div>
        </b-modal>
        <!-- Body -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col>
                        <h1 v-if="id" class="mb-0">
                            Update your Idea

                            <i
                                v-if="$apollo.loading"
                                class="fas fa-spinner fa-spin ml-1"
                            ></i>
                        </h1>
                    </b-col>

                    <b-col class="text-right">
                        <router-link
                            v-if="id"
                            :to="{
                                name: 'HackathonViewIdea',
                                params: {
                                    idea_id: id,
                                    hackathon_id: hackathon_id,
                                },
                            }"
                        >
                            <i class="fas fa-eye text-black-50"></i>
                        </router-link>
                    </b-col>
                </b-row>

                <h1 v-if="!id" class="mb-0">Submit an Idea</h1>

                <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                >
                    <b-form
                        role="form"
                        @submit.prevent="handleSubmit(submit_form)"
                    >
                        <base-input
                            :pill="true"
                            label="Idea Name*"
                            class="mb-3"
                            name="Idea Name"
                            :rules="{ required: true }"
                            placeholder="Enter your Idea's name"
                            v-model="form.name"
                            :disabled="form.loading.form"
                        >
                        </base-input>

                        <base-input
                            :pill="true"
                            label="Idea short description*"
                            class="mb-3"
                            name="Idea Description"
                            :rules="{ required: true }"
                            placeholder="A one liner that describes your idea"
                            v-model="form.description"
                            :disabled="form.loading.form"
                        >
                        </base-input>

                        <html-editor
                            :pill="true"
                            label="Idea detail description*"
                            class="mb-3"
                            name="Detailed Description"
                            :rules="{ required: true }"
                            placeholder="A detailled description of your idea"
                            v-model="form.detail_description"
                            :disabled="form.loading.form"
                        >
                        </html-editor>

                        <div class="text-right">
                            <base-button
                                type="warning"
                                :pill="true"
                                class="my-4 btn-success btn-right"
                                v-b-modal.delete_modal
                                v-if="id"
                                :disabled="
                                    form.loading.delete || form.loading.form
                                "
                                :loading="
                                    form.loading.delete || form.loading.form
                                "
                                :success="form.success.delete"
                            >
                                Delete
                            </base-button>
                            <base-button
                                type="primary"
                                :pill="true"
                                native-type="submit"
                                class="my-4 btn-success btn-right"
                                :disabled="
                                    form.loading.save || form.loading.form
                                "
                                :loading="
                                    form.loading.save || form.loading.form
                                "
                                :success="form.success.save"
                            >
                                {{ id != null ? "Update" : "Save" }}
                            </base-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import {
    GET_HACKATHON_IDEA_IDEA,
    GET_HACKATHON_HACKATHON_BASIC,
} from "@/graphql/queries";
import {
    CREATE_HACKATHON_IDEA_IDEA,
    UPDATE_HACKATHON_IDEA_IDEA,
    DELETE_HACKATHON_IDEA_IDEA,
} from "@/graphql/mutations";

// ORM
import {
    processHackathonRelayData,
    Hackathon,
} from "@/ORM/Hackathon/Hackathon.js";

export default {
    name: "HackathonCrudIdeaForm",
    components: { HtmlEditor },
    apollo: {
        get_hackathon_idea_idea: {
            query: GET_HACKATHON_IDEA_IDEA,
            result(data) {
                this.form.name = data.data.hackathonIdeaIdea.name;
                this.form.description = data.data.hackathonIdeaIdea.description;
                this.form.detail_description =
                    data.data.hackathonIdeaIdea.detailDescription;
                this.form.loading.form = false;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    idea_id: this.id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_idea_idea = data;
            },
            skip: true,
        },
        get_hackathon_basic: {
            query: GET_HACKATHON_HACKATHON_BASIC,
            result(data) {
                this.hackathon = processHackathonRelayData(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_basic = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            id: null,
            hackathon_id: null,
            hackathon: new Hackathon(),
            apollo_data: {
                get_hackathon_idea_idea: {},
                get_hackathon_basic: {},
            },
            form: {
                name: null,
                account_id: null,
                creator_id: null,
                hackathon_id: null,
                description: null,
                detail_description: "",
                loading: {
                    save: false,
                    delete: false,
                    form: false,
                },
                success: {
                    save: false,
                    delete: false,
                },
            },
        };
    },
    methods: {
        submit_form() {
            if (this.id) {
                this.update_idea();
            } else {
                this.create_idea();
            }
        },

        // Form Methods
        create_idea() {
            this.form.loading.save = true;
            this.form.loading.form = true;

            this.$apollo
                .mutate({
                    mutation: CREATE_HACKATHON_IDEA_IDEA,
                    variables: {
                        hackathon_id: this.hackathon_id,
                        account_id: store.getters.getAccountIdB64,
                        creator_id: store.getters.getUser.id_b64,
                        name: this.form.name,
                        description: this.form.description,
                        detail_description: this.form.detail_description,
                    },
                })
                .then((res) => {
                    if ("data" in res) {
                        if ("data" in res) {
                            this.id = res.data.hackathonIdeaCreateIdea.idea.id;

                            if (this.hackathon.ideas_require_approval) {
                                this.$notify({
                                    message: `Successfully created and submitted idea for approval.`,
                                    timeout: 4000,
                                    icon: "ni ni-check-bold",
                                    type: "success",
                                });
                            } else {
                                this.$notify({
                                    message: `Successfully created idea.`,
                                    timeout: 4000,
                                    icon: "ni ni-check-bold",
                                    type: "success",
                                });
                            }

                            this.form.loading.save = false;
                            this.form.success.save = true;
                            this.form.loading.form = false;
                            setTimeout(() => {
                                this.form.success.save = false;
                                this.$router.push({
                                    name: "HackathonMyIdeas",
                                    params: {
                                        hackathon_id: this.hackathon_id,
                                    },
                                });
                            }, 1000);
                        }
                    }
                    this.form.loading.save = false;
                    this.form.loading.form = false;
                })
                .catch((res) => {
                    this.form.loading.save = false;
                    // TODO: Create notification that something went wrong.
                });
        },
        update_idea() {
            this.form.loading.save = true;
            this.form.loading.form = true;
            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON_IDEA_IDEA,
                    variables: {
                        idea_id: this.id,
                        name: this.form.name,
                        description: this.form.description,
                        detail_description: this.form.detail_description,
                    },
                })
                .then((res) => {
                    if (this.hackathon.ideas_require_approval) {
                        this.$notify({
                            message: `Successfully updated and resubmitted idea for approval.`,
                            timeout: 4000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                    } else {
                        this.$notify({
                            message: `Successfully updated idea.`,
                            timeout: 2000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                    }

                    this.form.loading.save = false;
                    this.form.loading.form = false;
                    this.form.success.save = true;
                    setTimeout(() => {
                        this.form.success.save = false;
                    }, 1000);
                })
                .catch((res) => {
                    this.form.loading.form = false;
                    this.form.loading.save = false;
                    // TODO: Create notification that something went wrong.
                });
        },
        delete_idea() {
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON_IDEA_IDEA,
                    variables: {
                        idea_id: this.id,
                    },
                })
                .then((res) => {
                    this.form.loading.delete = false;
                    this.form.loading.form = false;
                    this.$bvModal.hide("delete_modal");

                    this.$router.push({
                        name: "HackathonMyIdeas",
                        params: {
                            hackathon_id: this.hackathon_id,
                        },
                    });
                })
                .catch((res) => {
                    this.form.loading.delete = false;
                    this.form.loading.form = false;
                    // TODO: Create notification that something went wrong.
                });
        },

        delete_idea_confirm() {
            this.form.loading.delete = true;
            this.delete_idea();
        },

        //Apollo Methods
        enable_get_hackathon_idea_idea() {
            this.$apollo.queries.get_hackathon_idea_idea.setOptions({
                fetchPolicy: "network-only",
            });

            if (!this.$apollo.queries.get_hackathon_idea_idea.skip) {
                this.$apollo.queries.get_hackathon_idea_idea.refetch();
            } else {
                this.$apollo.queries.get_hackathon_idea_idea.skip = false;
                this.$apollo.queries.get_hackathon_idea_idea.start();
            }
        },
        enable_get_hackathon_basic() {
            this.$apollo.queries.get_hackathon_basic.setOptions({
                fetchPolicy: "network-only",
            });

            if (!this.$apollo.queries.get_hackathon_basic.skip) {
                this.$apollo.queries.get_hackathon_basic.refetch();
            } else {
                this.$apollo.queries.get_hackathon_basic.skip = false;
                this.$apollo.queries.get_hackathon_basic.start();
            }
        },

        // Form mode selection
        get_idea_id() {
            if (this.$route.params.idea_id) {
                this.id = this.$route.params.idea_id;
                this.enable_get_hackathon_idea_idea();
                this.form.loading.form = true;
            } else {
                this.id = null;
            }
            // TODO: reroute to "update" if id is found.
        },
        get_hackathon_id() {
            if (this.$route.params.hackathon_id) {
                this.hackathon_id = this.$route.params.hackathon_id;
                this.enable_get_hackathon_basic();
            } else {
                this.hackathon_id = null;
                // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
            }
        },

        get_ids() {
            this.get_hackathon_id();
            this.get_idea_id();
        },
    },
    mounted() {
        this.get_ids();
    },
};
</script>

<style></style>
